<template>
  <div class="content__wrapper" :class="background">
    <HeaderComponent portal />
    <Notification />
    <main id="main" class="content-frame">
      <div class="content content--framed">
        <slot />
      </div>
    </main>
    <FooterComponent social-networks links seo-links show-go-top />
  </div>
</template>

<script>
import { backgroundMixin } from './layout_mixins'
import HeaderComponent from 'src/components/common/header/header.vue'
import Notification from 'src/components/common/notification/notification.vue'

import { defineAsyncComponent } from 'vue'

export default {
  name: 'PortalLayout',
  components: {
    HeaderComponent,
    FooterComponent: defineAsyncComponent(() =>
      import('src/components/common/footer/footer.vue')
    ),
    Notification
  },
  mixins: [backgroundMixin],
  props: {
    meta: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'layout';

.content__wrapper {
  display: flex;
}
</style>
